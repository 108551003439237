import React from 'react';
import T from 'prop-types';

const CloseButton = ({ closeToast }) => (
  <span className="hicon-close-2 toast-close-btn" onClick={closeToast} />
);

CloseButton.propTypes = {
  closeToast: T.func.isRequired,
};

export default CloseButton;
