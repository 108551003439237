import React from 'react';
import T from 'prop-types';
import cx from 'classnames';
import { ToastContainer as ToastifyContainer, Zoom } from 'react-toastify';

import CloseButton from './CloseButton';

const ToastContainer = ({
  containerId = 'toast-container',
  position = 'bottom-left',
  autoClose = 5000, // 10 seconds
  closeButton = CloseButton,
  hideStaticBar = true,
  hideProgressBar = false,
  pauseOnHover = true,
  pauseOnFocusLoss = true,
  closeOnClick = false,
  className,
  toastClassName,
  bodyClassName,
  progressClassName,
}) => (
  <ToastifyContainer
    containerId={containerId}
    position={position}
    autoClose={autoClose}
    closeButton={closeButton}
    transition={Zoom}
    hideProgressBar={hideProgressBar}
    pauseOnHover={pauseOnHover}
    pauseOnFocusLoss={pauseOnFocusLoss}
    closeOnClick={closeOnClick}
    newestOnTop
    className={className}
    toastClassName={cx('Toastify__toast-theme--blue', { 'Toastify__toast-static-bar': !hideStaticBar }, toastClassName)}
    bodyClassName={bodyClassName}
    progressClassName={progressClassName}
    icon={false}
  />
);
// API reference: https://fkhadra.github.io/react-toastify/api/toast-container/
ToastContainer.propTypes = {
  containerId: T.string,
  position: T.oneOf(['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']),
  autoClose: T.oneOfType([T.bool, T.number]),
  closeButton: T.oneOfType([T.node, T.bool]),
  hideProgressBar: T.bool,
  hideStaticBar: T.bool,
  pauseOnHover: T.bool,
  pauseOnFocusLoss: T.bool,
  closeOnClick: T.bool,
  className: T.string,
  toastClassName: T.string,
  bodyClassName: T.string,
  progressClassName: T.string,
};

export default ToastContainer;
