import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const LoadingSpinner = ({ className, size, id = '', strokeWidth = '10' }) => (
  <div className={cx('spinner', className, size && `spinner__${size}`)}>
    <svg width="100%" height="100%" viewBox="0 0 100 100" color="#228BF4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={`spinner-secondHalf${id}`}>
          <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
        </linearGradient>
        <linearGradient id={`spinner-firstHalf${id}`}>
          <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
        </linearGradient>
      </defs>

      <g strokeWidth={strokeWidth}>
        <path stroke={`url(#spinner-secondHalf${id})`} d="M 5 50 A 44 44 0 0 1 95 50" />
        <path stroke={`url(#spinner-firstHalf${id})`} d="M 95 50 A 44 44 0 0 1 5 50" />
        <path stroke="currentColor" fill="currentColor" strokeLinecap="round" d="M 5 50 A 44 44 0 0 1 5 50" />
      </g>
    </svg>
  </div>
);

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  id: PropTypes.string, // fixes the color issue of more thn 1 spinner present in same block.
  strokeWidth: PropTypes.string,
};

export default LoadingSpinner;
