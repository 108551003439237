import React from 'react';
import T from 'prop-types';

import Button from '../../Buttons/Button';

const ToastContent = ({
  closeToast,
  onClick,
  toastContent,
  buttonContent,
}) => {
  const handleClick = () => {
    onClick();
    closeToast();
  };

  return (
    <div className="toast-content">
      <div className="toast-content-node">
        {toastContent}
      </div>
      <Button
        size="sm"
        className="mt-xs"
        onClick={handleClick}
      >
        {buttonContent}
      </Button>
    </div>
  );
};

ToastContent.propTypes = {
  closeToast: T.func,
  onClick: T.func,
  toastContent: T.node.isRequired,
  buttonContent: T.node.isRequired,
};

export default ToastContent;

export const GlobalCableToastContent = ({
  title,
  description,
  renderButton,
  closeToast,
}) => (
  <div className="global-cable-toast">
    {title && <h2 className="global-cable-toast__title">{title}</h2>}
    {description && <p className="global-cable-toast__description">{description}</p>}
    {renderButton && <div className="global-cable-toast__btn-slot">{renderButton({ closeToast })}</div>}
  </div>
);

GlobalCableToastContent.propTypes = {
  title: T.node,
  description: T.node,
  renderButton: T.func,
  closeToast: T.func,
};
