import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  ToastContainer,
} from '../../components/Toasts';

const rootNode = document.getElementById('global-toast-container-root');

document.addEventListener('DOMContentLoaded', () => {
  const root = createRoot(rootNode);
  root.render(<ToastContainer />);
});
