import React, { forwardRef } from 'react';
import T from 'prop-types';
import cx from 'classnames';
import LoadingSpinner from '../LoadingSpinner';

const emptyCb = () => {};

// eslint-disable-next-line prefer-arrow-callback
const Button = forwardRef(function Button({
  onClick = emptyCb,
  children,
  type = 'button',
  size,
  shape,
  variant = 'primary',
  active,
  loading,
  disabled,
  className,
  loadingId,
  ...restProps
}, ref) {
  const TagName = restProps.href ? 'a' : 'button';

  return (
    <TagName
      ref={ref}
      type={type}
      className={cx('hms-btn', className, {
        'hms-btn--active': active,
        'hms-btn--disabled': disabled,
        [`hms-btn--${size}`]: size,
        [`hms-btn--${shape}`]: shape,
        [`hms-btn--${variant}`]: variant,
      })}
      disabled={disabled}
      onClick={onClick}
      {...restProps}
    >
      {loading && (
        <span className={cx('hms-btn__loading', { 'hms-btn__loading--active': loading })}>
          <LoadingSpinner size="sm" id={loadingId} />
        </span>
      )}
      <span className={cx('hms-btn__children', { 'hms-btn__children--loading': loading })}>{children}</span>
    </TagName>
  );
});

Button.propTypes = {
  onClick: T.func,
  children: T.node.isRequired,
  type: T.oneOf(['button', 'submit', 'reset']),
  size: T.oneOf(['sm', 'lg']),
  shape: T.oneOf(['square']),
  variant: T.oneOf(['primary', 'secondary', 'light', 'danger', 'green']),
  active: T.bool,
  loading: T.bool,
  disabled: T.bool,
  className: T.string,
  loadingId: T.string,
};

export default Button;

// Global component designed according to design system
